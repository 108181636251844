'use client';


const isProduction = process.env.NEXT_PUBLIC_MAIN_URL === 'https://www.zhaket.com';
export default function Iframes() {

  return (
    <>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${isProduction ? 'GTM-5VKLCXW' : 'GTM-NL7KZRWG'}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      {isProduction && <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TSBLJGRN"
                height="0" width="0" style="display:none;visibility:hidden" />
      </noscript>}
    </>
  )
    ;
}
