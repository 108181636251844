'use client';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

import { eventTracker } from '@/lib/service-workers/index';

import { useGetProfile } from '@/components/layout/services/hooks';

function ZeblinePageViewEvent() {
  const { profile } = useGetProfile();
  const date = new Date().toISOString();
  const router = useRouter();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      eventTracker(profile?.mobile, 'page_view', date, {
        page_url: window?.location?.href,
      });
    }
  }, [profile, router]);

  return null;
}

export default ZeblinePageViewEvent;
