'use client';
import Script from 'next/script';
import DOMPurify from 'isomorphic-dompurify';

const isProduction = process.env.NEXT_PUBLIC_MAIN_URL === 'https://www.zhaket.com';

export default function GoogleAnalytics() {
  const gtmScript = DOMPurify.sanitize(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);})(window,document,\'script\',\'dataLayer\',\'${isProduction ? 'GTM-5VKLCXW' : 'GTM-NL7KZRWG'}\');`);

  const gtmScript2 = DOMPurify.sanitize(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-TSBLJGRN');`);

  return (
    <>
      <Script
        type="text/javascript"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: gtmScript
        }}
      />
      {isProduction && (
        <Script
          type="text/javascript"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: gtmScript2
          }}
        />
      )}
    </>
  );
}
