import {create} from 'zustand';
import { getCookie } from '@/lib/cookies';

interface ConsentStoreType {
  consent: string;
  setConsent: (consent: string) => void;

}

export const useCookieConsentStore = create<ConsentStoreType>((set) => ({
  consent: getCookie('cookie_consent'),
  setConsent: (consent: string) => set({ consent })
}));
