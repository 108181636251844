// import { QueryCache, QueryClient } from '@tanstack/react-query';
// import { cache } from 'react';

// const queryCache = cache(
//   () =>
//     new QueryCache({
//       onError: (error) => {
//         console.log(error);
//       },
//       onSuccess: (data) => {
//         console.log(data);
//       },
//       onSettled: (data, error) => {
//         console.log(data, error);
//       },
//     }),
// );

// const getQueryClient = cache(() => new QueryClient());
// export default getQueryClient;

import { defaultShouldDehydrateQuery, isServer, QueryClient } from '@tanstack/react-query';

function makeQueryClient() {
  const isProduction = process.env.NEXT_PUBLIC_MAIN_URL === 'https://www.zhaket.com';
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 15 * 60 * 1000,
        refetchOnMount: false,
        refetchOnWindowFocus: !isProduction,
        retry: false,
        retryOnMount: false,
      },
      dehydrate: {
        // include pending queries in dehydration
        shouldDehydrateQuery: (query) =>
          defaultShouldDehydrateQuery(query) || query.state.status === 'pending',
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

export default function getQueryClient() {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

export const getQueryData = <T>(queryKey) => getQueryClient().getQueryData<T>(queryKey);
