'use client';
import { useEffect } from 'react';

const isProduction = process.env.NEXT_PUBLIC_MAIN_URL === 'https://www.zhaket.com';

const ScriptInitializer = () => {
  useEffect(() => {
    const initializeZebline = () => {
      if (window.zebline && typeof window.zebline.init === 'function') {
        window.zebline.init({
          token: isProduction
            ? '1$U2VnbWVudCFuMA$rAXsqvWC1x1683710235123'
            : 'xAqrP44Gwfp03W8FtDw4GA5bQEx72mVEbilz1nRm3h451I3Szm',
          licence_code: isProduction
            ? '1$U2VnbWVudCFuMA$N8wr9XcCE+1683710235139'
            : '4$U2VnbWVudCFuMA$kCPqggoLMS1697308899874',
          notificationRequest: true,
        });
        return true;
      }
      return false;
    };

    const retryInitialization = () => {
      if (!initializeZebline()) {
        window.requestAnimationFrame(retryInitialization);
      }
    };

    if (!initializeZebline()) {
      window.requestAnimationFrame(retryInitialization);
    }
  }, []);

  return null;
};

export default ScriptInitializer;
