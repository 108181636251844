'use client';

import { useEffect, useState } from 'react';
import Link from 'next/link';

import Container from '@/components/@base/container';
import Button from '@/components/@base/button';
import { Text } from '@/components/@base/typography';
import XImage from '@/components/@base/x-image';

import imagesList from '@/constant/images-list';
import localization from '@/constant/localization';
import { setCookie } from '@/lib/cookies';
import { useGetUserIP } from '@/components/layout/services/hooks';
import { useCookieConsentStore } from '@/store/consent.sote';

const CookiePermissionAlert = () => {
  const { consent, setConsent } = useCookieConsentStore();
  const [show, setShow] = useState(false);
  const { refetch } = useGetUserIP();
  const termsRoute = process.env.NEXT_PUBLIC_TERMS_ROUTE;

  useEffect(() => {
    if (consent !== 'accepted' && consent !== 'not-accepted') {
      refetch().then((res) => {
        if (res.data?.requires_permission) {
          setShow(true);
        } else {
          setCookie('cookie_consent', 'accepted', { maxAge: 31536000 });
          setConsent('accepted');
        }
      });
    }
  }, [consent, refetch, setConsent]);

  useEffect(() => {
    if (typeof window === 'undefined' || !window.gtag) return;

    if (consent === 'accepted') {
      window.gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted'
      });
      setShow(false);
    } else if (consent === 'not-accepted') {
      setShow(false);
    }
  }, [consent]);

  const handleCookiePermission = (val: boolean) => {
    if (val) {
      setCookie('cookie_consent', 'accepted', { maxAge: 31536000 });
      setConsent('accepted');
    } else {
      setCookie('cookie_consent', 'not-accepted', { maxAge: 604800 });
      setConsent('not-accepted');
    }
    setShow(false);
  };

  if (!show || window.location.href === termsRoute || process.env.NEXT_PUBLIC_DISABLE_COOKIE_PERMISSION === 'true') {
    return null;
  }

  return (
    <Container
      className="fixed w-full h-dvh top-0 left-0 z-[10000000000] p-4 bg-black/50 flex justify-center items-end"
    >
      <Container
        className="flex flex-col md:flex-row gap-2 md:gap-3 items-center justify-between max-w-[1286px] w-full h-max p-4 rounded-xl bg-[#F2F8FF]"
      >
        <Container className="flex items-center">
          <XImage width={24} height={24} src={imagesList.icons.shieldIcon} alt="shield" />
          <Container className="flex">
            <Text className="pr-[10px] text-[15px] text-[#1A73E8]">
              {localization.cookiePermission}
              <Link
                href={termsRoute}
                target="_blank"
                className="font-[700] text-[15px] text-[#1A73E8]"
              >
                {localization.viewConditions}
              </Link>
            </Text>
          </Container>
        </Container>

        <Container className="flex gap-[10px]">
          <Button
            onClick={() => handleCookiePermission(false)}
            className="w-[78px] border-[1px] border-[#48A1FB] bg-white text-[15px] text-[#48A1FB] hover:bg-[#A9CAEF56]"
          >
            {localization.reject}
          </Button>

          <Button
            onClick={() => handleCookiePermission(true)}
            className="h-10 w-[115px] bg-[#48A1FB] text-[15px] text-white hover:bg-[#48A1FBC0]"
          >
            {localization.acceptAll}
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

export default CookiePermissionAlert;
